import React, { useState, useEffect } from 'react';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import PlayerConstants from '../../../utils/PlayerConstants';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import Datahelper from '../../../utils/DataHelper'
import PropTypes from 'prop-types';
import Acc from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import Slider from '@mui/material/Slider';
import EditIcon from '@material-ui/icons/Edit';
import CustomSelect from '../../../components/ui/CustomSelect/CustomSelect';
import CustomSweetAlert from '../../../components/ui/CustomSweetAlert/CustomSweetAlert';



/**
 * 
 * ImageMapperPanel editor panel used to modify the propertis of ImageMapper component
*/
const ImageMapperPanel = ({ img, areas,handleClose, shape, background, handleProperties, crop_area, background_color, text_color, cename ,edit_idx, id, handleCeAssets }) => {
    const [data, setData] = useState({ img, areas,background, background_color, shape, text_color,edit_idx});
    const [mapAreas, setMapAreas] = useState(JSON.parse(JSON.stringify(areas)));
    const [expanded, setExpanded] = useState(0);
    const [highlightBackround, setHighlightBackround] = useState((areas?.length > 0) ? areas[0]?.style?.background : '#13984B');
    const [editMode, setEdit] = React.useState(null);
    const [isError, setIsError] = useState(false);
    const [cropShape, setCropShape] = useState(shape);
    const [hasPosition, setHasPosition] = useState(false);
   
    const getMaxPanel = Object.values(PlayerConstants.COMPONENTS_LIST).filter((el) => el.name === cename);
    let max_count = 0
    max_count = getMaxPanel[0].max_count;


        /* Tracking the index changes */
        const handleChange = (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
            setEdit(null);
        };

        const handleChangeTitle = (e, i) => {
            setMapAreas(areas);
            let items=[...areas];
            items[i] = { ...items[i], [e.target.name]:e.target.value };
            setMapAreas(items);
            updateProps({...data,areas: mapAreas,edit_idx:edit_idx});
        }

    
        const handleSave = (e, index) => {
            setEdit(null);
            e.stopPropagation();
        }


      /* handleProperties change track and updating*/
      useEffect(() => {
        handleProperties({ ...data,img:img,areas:mapAreas, crop_area: null, shape:cropShape});
       if(data.length === 0){setHighlightBackround('#13984B')}
    }, [data]);


    useEffect(() => {
        setMapAreas(areas)
        if (max_count > areas.length && crop_area !== null && edit_idx === null ) {
            // addAccordion();
        }
        if(edit_idx !== null){
            setExpanded(edit_idx)
            // updateProps({...data,edit_idx:null});
        }
    }, [crop_area, edit_idx]);

    const handleEdit = (e, index) => {
        setEdit(index);
        e.stopPropagation();
    }

    // const curentEditArea = (index) => {
    //     setMapAreas(areas);
    //     let currentShape=areas[index]?.shape;
    //     setCropShape(currentShape)
    //     updateProps({...data,areas:areas,edit_idx:index,crop_area:null,shape:shape});
    //     // setTimeout(() => {
    //     //     // Delay this action by second
    //     //     handleClose()
    //     //   },200)
        
    // }
    // const addAccordion = async() => {
    //     let newArea = {};
    //     if (crop_area !== null && shape) {
    //         setIsError(false);
    //         const { x, y, width, height } = await crop_area;
    //         let style={
    //             background:highlightBackround,
    //             borderRadius:shape === 'rect'?'':'50%',
    //             opacity:mapAreas[0]?.style?.opacity ||1
    //         }
    //         let bgcl = (background_color ? background_color : '#13984B');
    //         newArea = {
    //             width: `${width}%`,
    //             height: `${height}%`,
    //             left: `${x}%`,
    //             top: `${y}%`,
    //             style: { ...style},
    //             message:`message`,
    //             title:`Label ${ mapAreas.length+1}`,
    //             isOpen:false,
    //             shape:cropShape
    //           };
    //         setMapAreas([...areas, newArea]);
    //         setHighlightBackround(highlightBackround);
    //         setExpanded(areas?.length);
    //         updateProps({...data,areas:await mapAreas,crop_area: null,shape:cropShape,background_color:bgcl});
    //     } else {
    //         setIsError(true);
    //     }
    // }


  // Dynamic uploaded file path has to replaced
    const handleMedia = (Upload) => {
        setMapAreas(areas)
        try {
            let prop={...data,img:Upload?.name,areas:areas,edit_idx:edit_idx};
            handleCeAssets(data.img,Upload?.name,prop);
            handleProperties(prop);
        } catch (e) {
            console.debug(e);
        }
    }
const handlePosition=(e)=>{
    setData((pre)=>{
        let previous={...pre,background:hasPosition?.value }
        handleProperties(previous);
        setHasPosition(false)
        return{ ...previous }
    })
}

    const shapeSelection = (type) => {
        setMapAreas(areas);
        shape=type;
        let props={...data,shape:type,areas:areas,crop_area:null,edit_idx:edit_idx};
        setCropShape(type);
        updateProps(props);
    }

    const activeClass = (value) => {
        if (shape === value || shape === value) {
            return 'active'
        }
        return ''
    }

    const handleHighlighterChange = (e) => {
        let editArea = areas?.map((el, idx) => {
            return {...el,style:{...el?.style,[e?.target?.name]:[e?.target?.value]}}
        });
        setHighlightBackround(e?.target?.value);
        setMapAreas(editArea);
        updateProps({...data,areas:editArea});
    }

    const handleBgcolorChange = (e) => {
        background_color=e?.target?.value;
        setMapAreas(areas);
        updateProps({
            ...data,
            background_color:e?.target?.value,
            areas:areas
        });
    }

    const handleTextColorChange = (e) => {
        text_color=e?.target?.value;
        updateProps({...data,text_color:e?.target?.value,areas:areas});
    }

    const textContentChange = (message, i) => {
        setMapAreas(areas);
        let items=[...areas];
        items[i] = { ...items[i], message:message };
        let props={...data,shape:shape,areas:items,crop_area:null};
        setMapAreas(items);
        updateProps(props);
    }


    const handleDelete = (e, index) => {
        if(mapAreas?.length > 1){
        const removed = mapAreas.filter((e, i) => i !== index);
        setMapAreas(removed);
        updateProps({ ...data, areas:removed,edit_idx:null})
        }
    }

    const handleOpacityChange = (e) => {
        let editArea = areas?.map((el, idx) => {
            return {...el,style:{...el.style,[e.target.name]: e.target.value}}
        });
        setMapAreas(editArea);
        updateProps({...data,areas:editArea,img:img});
    }


    const updateProps = (props) => {
        let updatedProps={img, areas, background_color,shape,text_color,edit_idx};
           updatedProps={...updatedProps,...props};
           handleProperties(updatedProps);
           setData(updatedProps);
    }
    const handlePositionAction=(val)=>{
        if(data?.background!== val?.value)setHasPosition(val);
    }
 
 
    return (
<div className='imagmap-prop-container imageHighlight-prop-container'>
    <div className={``}>
        <div className='row mt-15 px-2'>
            <div className="mb-3 col-sm-12 col-md-12 col-lg-12">
                <label htmlFor="formFile" className="label">Browse Image</label>
                <UploaderPanel accept={'image'} fileName={data?.img}  name={`${id}_${Datahelper.getTime()}-image`} setUploadedData={handleMedia} />
            </div>
            <div className='mb-3 col-sm-12 col-md-12 col-lg-6' >
            <CustomSelect
                value={data?.background||'unset'}
                options={Position}
                onChange={handlePositionAction}
                label='Select Position'
                style={{width:'100%'}}
            />
            </div>
            <div className="mb-3 col-sm-12 col-md-12 col-lg-6">
                <label htmlFor="formFile" className="form-label label">Select tool to draw a highlight</label>
                <div className="tool_container">
                    <CircleOutlinedIcon className={`tool_icon circle ${activeClass('circle')}`} onClick={() => shapeSelection('circle')} />
                    <RectangleOutlinedIcon className={`tool_icon circle ${activeClass('rect')}`} onClick={() => shapeSelection('rect')} />
                </div>
                {isError && <p className='error_msg'>Select your tool to mark the area at image</p>}
            </div>
             <div className='mb-3 col-sm-12 col-md-12 col-lg-6' >
                            <label className='label' id="text-radio-buttons-group-label">Highlight Opacity</label>
                            <Slider className="ce-slider" value={mapAreas[0]?.style?.opacity|| 0.9} name="opacity" onChange={handleOpacityChange} aria-label="Default" valueLabelDisplay="auto" step={0.1}
                                min={0.0}
                                max={1.0} />
            </div>
            <div className="mb-3 col-sm-12 col-md-12 col-lg-6">
                <label htmlFor="formFile" className="form-label label">Select Colors</label>
                <div className="color_container">
                    <label>
                        <TextField type='color' id="outlined-basic" className='color_select ' spellCheck={false} name='background' defaultValue={highlightBackround} onChange={handleHighlighterChange} />
                        Highlight
                    </label>
                    <label>
                        <TextField type='color' className='color_select ' spellCheck={false} name='background_color' defaultValue={background_color} onChange={handleBgcolorChange} />
                        Background
                    </label>
                    <label>
                        <TextField type='color' className='color_select ' spellCheck={false} name='text_color' defaultValue={text_color} onChange={handleTextColorChange} />
                        Text
                    </label>
                </div>
            </div>
        </div>
        {
            areas && (areas?.length > 0) && areas?.map((el, index) => {
                return <Acc key={index} expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {editMode === index ? <>
                            <TextField className='list-title' id="outlined-basic" inputProps={ { maxLength: 35 } } spellCheck={false} name='title' defaultValue={el?.title} onClick={(e) => e.stopPropagation()} onChange={(e) => handleChangeTitle(e, index)} />
                            <CheckIcon className='save-prop-accordion' onClick={(e) => handleSave(e, index)} />
                        </> : <> <Typography className={``}>{el?.title}  </Typography><EditIcon className='edit-prop-accordion' onClick={(e) => handleEdit(e, index)} /> <DeleteIcon className='delete-prop-tabs' onClick={(e) => handleDelete(e, index)} /> </>}
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className='description-text label'>Text Content</p>
                        <RichTextEditor data={el?.message} eventHandler={(e) => textContentChange(e, index)} />
                    </AccordionDetails>
                </Acc>
            })
        }
        {/* <span title={'Add Accordion'} onClick={addAccordion}><AddCircle className='add-mapping' /></span> */}
    </div>
    <div className='warning-popup'>
        <CustomSweetAlert
        warning
        showCancel
        show={Boolean(hasPosition)}
        confirmBtnText={"OK"}
        cancelBtnText={"CANCEL"}
        confirmBtnBsStyle="danger"
        onCancel={ () =>setHasPosition(false)} 
        title={<p className="sweet-title">{`Please update the hotspot area to align with the new image placement.`}</p>}
        onConfirm={handlePosition}/></div>
</div>
  )
}

export const Position =[
    { label: 'Actual Size', value: 'contain' },
    { label: 'Spread', value: 'cover' },
    { label: 'Center & Spread', value:'unset' },
]

export default ImageMapperPanel


ImageMapperPanel.propTypes = {
    /** Src contains source url of the image */
    img: PropTypes.string.isRequired,
    /** Areas content area for the ImageMapper */
    areas: PropTypes.array.isRequired,
    /** Shape content shape for the ImageMapper */
    shape: PropTypes.string.isRequired,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func,
    /** function handler used to close the edited Panel*/
    handleClose: PropTypes.func,
    /** crop_area content has tracking ImageMapper Changes*/
    crop_area: PropTypes?.object,
    /** background_color content has tracking ImageMapper Changes*/
    background_color: PropTypes?.string,
    /** text_color content has tracking ImageMapper Changes*/
    text_color: PropTypes?.string,
    /** function handler used to update the Assets */
    handleCeAssets : PropTypes.func,
}