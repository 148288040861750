import React, { useRef, useState, useEffect } from 'react'
import './printcoursemap.scss'
import { Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactToPrint from 'react-to-print';
import Celogo from '../../../assets/img/ce_logo_skill_comp.png';
import DataHelper from "../../../utils/DataHelper"
import AppButton from '../AppButton/AppButton';
import t from '../../../translation/useTranslate';
import PlayerConstants from '../../../utils/PlayerConstants';
import BuildHelper from '../../../utils/BuildHelper';

const PrintCourseMap = ({defaultUser, topic}) => {
    const courseInfo = useSelector(state => state.courseInfo)
    const trackInfo = useSelector(state => state.courseTracking)
    const userInfo = useSelector(state => state.userInfo)
    const today = new Date();
    const date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear()
    const printcrsRef = useRef('')
    const [ topicStatus, setStatus ] = useState(0)
    const [logoPath, setLogoPath] = useState(window.ce.isStandalone ? window.ce.siPath : Celogo);
    const isRtl=(PlayerConstants?.RTL_LANGUAGES?.includes(BuildHelper.defaultLanguage() || 'en_US') && BuildHelper.isLearner());
    useEffect(() => {
        try{
            const clientId = userInfo?.user?.pvtid 
            const fileExtension = userInfo?.user?.logo ? userInfo?.user?.logo.split(".").pop() : 'png';
            if(clientId && fileExtension && Number(clientId)!==222 && !window.ce.isStandalone){
                    setLogoPath('https://dg7zdxz92ywv.cloudfront.net/platform/clientlogo/'+clientId+'.'+fileExtension)
            }
        }catch{}
    }, [userInfo])
    useEffect(() => {
        const topicFinished = () => {
            const finishedTopic = Object.values(courseInfo?.flatten).filter((val, index) => {
                    return Number(val.p) == 100 
            });
            setStatus(finishedTopic.length)
        }
        topicFinished(courseInfo.flatten)
    }, [courseInfo])

    const topicsCalculator = (fl) => {
        let crs = { sec: 0, tps: {len: 0, topics: []}, }
        for (var key in fl) {
            if (fl[key].lrncontent !== null && fl[key].lrncontent !== undefined) {
                crs.tps.len++;
                crs.tps.topics.push(fl[key])
                if (fl[key].children) {
                    crs.sec++;
                }
            }
        }
        return crs.tps;
    }
    const addPaddingLeft = (nodeId)=>{
        return nodeId.length !== 3 ? nodeId.length*8:nodeId.length
     }
    return (
        <>
        {topic === 'CourseMap' && <>
        <div className={`print-course-container ${isRtl ? "rtl-print-course-container" : ""}`} ref={printcrsRef}>
            <div className='container'>
                <div className='row top-print-row'>
                    <div className='col-md-8 logo-container'>
                        <div className='comp-logo'>
                            <img src={logoPath} alt="" />
                        </div>
                    </div>
                    <div className='col-md-4 text-right noPrint'>
                        <ReactToPrint
                            trigger={() => <AppButton theme={"primary1"} size="small">{t("PRINT")}</AppButton>}
                            content={() => printcrsRef.current}
                        />
                    </div>
                </div>
                <div className='printable-area' >
                    <div className='row course-print-row'>
                        <div className='col-md-7 course-print-information'>
                            <div className='course-print-overview'>
                                <p>{t("CRS_NAME")}: <span dangerouslySetInnerHTML={{ __html:courseInfo?.courseAttributes?.coursename || '' }} /></p>
                                <p>{t("USR_NAME")}: <span>{userInfo?.user?.firstname || window?.ce?.ceuser?.firstname || "Test User"} {userInfo?.user?.lastname || window?.ce?.ceuser?.lastname || ''}</span></p>
                            </div>
                        </div>
                        <div className='col-md-5 course-print-topic'>
                            <div className='course-print-topic-info'>
                                <p>{t("TOT_TOPIC")}: <span>{topicsCalculator(trackInfo.flatten).len - 1}</span></p>
                                {/* <p>Time Spent: <span>{'00:13:36'}</span></p> */}
                            </div>
                            <div className='course-print-date'>
                                <p>{t("T_FINISH")}: <span>{ topicsCalculator(trackInfo.flatten).len > topicStatus ? topicStatus : topicStatus-1 }</span></p>
                                <p>{t("PRINT_ON")}: <span>{DataHelper.MonthDayYearFormat(date)}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className='course-tree-menu-print'>
                        <div className='course-nav-tree'>
                            <div className='print-nav'>
                                <div className='print-course-list'>
                                    <List component="nav" className='print-list-fixed row'>
                                        {Object.values(courseInfo?.flatten).slice(1).map((el, index) => <ListItem key={el.nodeId} button className='col-md-12'>
                                            <ListItemText id={el.nodeId} style={{paddingLeft:addPaddingLeft(el.nodeId)}} className={`print-primary-coursemap ${isRtl ? "rtl-print-primary-coursemap":""}`} primary={el.title} />
                                            <span>{Number(el.p) == 100 ? t("COMPLETED"): (el.status > -1 ? t("VISITED"): t("INCOMPLETE"))}</span>
                                        </ListItem>)}
                                    </List>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> </>}

        </>
    )
}

PrintCourseMap.defaultProps = {
    defaultUser: {
        userName: 'Ben Watson',
        email: 'user@user.com'
    }
}

PrintCourseMap.propTypes = {

}

export default PrintCourseMap
