import React from 'react'
import Acc from '@material-ui/core/Accordion';
import useForceUpdate from 'use-force-update';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
// import CKeditor from '../CKeditor/CKeditor';
// import PlayerConstants from '../../../utils/PlayerConstants';
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import PropTypes from 'prop-types'
import { useEffect } from 'react';
import Datahelper from '../../../utils/DataHelper'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import ACLHelper from '../../../utils/ACLHelper';
import { getPlaceHolderImg } from '../../../utils/MediaConfig';

const HalfImageTextPanel = ( { items, handleProperties, hideTitle, cename, textPosition, id, handleCeAssets } ) => {
    const forceUpdate = useForceUpdate()
    const [ expanded, setExpanded ] = React.useState( 0 );
    const [ list, setList ] = React.useState( JSON.parse( JSON.stringify( items ) ) )
    let max_count = 8

    // const getMaxPanel = Object.values(PlayerConstants.COMPONENTS_LIST).filter((el) => el.name === cename)
    // max_count = getMaxPanel[0].max_count

    useEffect( () => {
        handleProperties( { items: list } )
    }, [ list ] )

    const handleChange = ( panel ) => ( event, isExpanded ) => {
        setExpanded( isExpanded ? panel : false );
    };

    // const handleChangeInput = ( e, id ) => {
    //     list[ id ][ e.target.name ] = e.target.value
    //     setList( list )
    //     handleProperties( { items: list } )
    // }

    const handleDelete = ( e, index ) => {
        if ( list.length !== 1 ) {
            const removed = list.filter( ( e, i ) => i !== index )
            setList( removed )
            forceUpdate()
            handleProperties( { items: removed } )
        }
        e.stopPropagation()
    }

    const addAccordion = () => {
        if ( max_count > list?.length ) {
            const maxId = Math.max(...list?.map(item => item?.id), 0)||1;
            list.push( {
                id: maxId + 1,
                title: 'Title',
                description: 'Description',
                isHalf: true,
                isImageRight: ( list.length + 1 ) % 2 === 0 ? true : false,
                imageUrl: getPlaceHolderImg(600)
            } )
            setList( list )
            handleProperties( { items: list } )
            forceUpdate()
        }
    }

    /**
     * 
     * @param {} value 
     * data html string used for description of the component
     */
    const eventHandlers = ( value, i ) => {
        list[ i ][ 'description' ] = value
        setList( list )
        handleProperties( { items: list } )
    }

    const handleMedia = ( data, index ) => {
        try {
            if ( data ) {
                const updatedList=[...list];
                updatedList[ index ] = { ...updatedList[ index ], imageUrl: data.name };
                handleCeAssets(list[ index ]?.imageUrl,data?.name,{ items: updatedList })
                setList( updatedList );
                handleProperties( { items: updatedList } )
            }
        }
        catch ( e ) {
            console.log( e );
        }
    }

    const imagePositionChange = ( value, i ) => {
        list[ i ][ 'isHalf' ] =( value === 'center' ) ? false : true;
        list[ i ][ 'isImageRight' ] = ( value === 'Right' ) ? true : false;
        setList( list )
        handleProperties( { items: list } )
    }

    const textPositionChange = ( value, i ) => {
        list[ i ][ 'textPosition' ] = value
        setList( list )
        handleProperties( { items: list } )
    }

    const handleBgColorChange =(e, i)=>{
        list[ i ][e.target.name]=  e.target.value;
        setList( list )
        handleProperties( { items: list } )
    }

    return (
        <div className='half-image-panel-container'>
            <div className={ `card-prop-main-ui` }>
                {
                    list && ( list.length > 0 ) && list.map( ( el, index ) => {
                        return <Acc key={ index } expanded={ expanded === index } onChange={ handleChange( index ) }>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={ `label-bold` }>{ `Card ${ index + 1 }` } </Typography>
                                <DeleteIcon className='delete-prop-card' onClick={ ( e ) => handleDelete( e, index ) } />
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='row'>
                                    { ACLHelper.isFeatureEnabled(4,2) && 
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className="card-prop-file">
                                            <UploaderPanel accept={ 'image' } 
                                            fileName={list[index]?.imageUrl} 
                                            name={ `${ id }_${ Datahelper.getTime() }-${ index + 1 }` }
                                            setUploadedData={(el)=> handleMedia(el,index) } 
                                              styles={{
                                                po:false,
                                                color:list[index]?.color ||"rgba(0, 0, 0, 0)",
                                              }}
                                              bgChange={(e) => handleBgColorChange(e,index)}
                                              />
                                        </div>
                                        {/* {!hideTitle && <div className='card-prop-title'>
                                    <TextField id="outlined-basic" spellCheck={false} name='title' label="Title" variant="outlined" defaultValue={el.title} onChange={(e) => handleChangeInput(e, index)}/>
                                </div> } */}
                                    </div>}

                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        {/* <FormLabel id="demo-radio-buttons-group-label">Image Alignment</FormLabel> */}
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            // defaultValue="Left"
                                            value={el?.isHalf ? (el?.isImageRight ? 'Right' : 'Left') :'center'}
                                            name="radio-buttons-group"
                                            className='row-radio-group'
                                        >
                                          <p className='label' id="demo-radio-buttons-group-label"> Align Image </p>
                                          <FormControlLabel className='radio-chk' value="Right" control={
                                                <Radio onChange={ () => imagePositionChange( 'Right', index ) } /> }
                                                label="Left" />
                                             <FormControlLabel className='radio-chk' value="center" control={
                                                <Radio onChange={ () => imagePositionChange( 'center', index ) } /> }
                                                label="center" />
                                            <FormControlLabel className='radio-chk' value="Left" control={
                                                <Radio onChange={ () => imagePositionChange( 'Left', index ) } /> }
                                                label="Right" />
                                        </RadioGroup>
                                    </div>

                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        {/* <FormLabel id="text-radio-buttons-group-label">Text Alignment</FormLabel> */}
                                        <RadioGroup
                                            row
                                            aria-labelledby="text-radio-buttons-group-label"
                                            defaultValue={ el?.textPosition !== undefined ?  el?.textPosition : "center" }
                                            name="radio-buttons-group"
                                            className='row-radio-group align-text-radio'
                                        >
                                             <p className='label' id="demo-radio-buttons-group-label"> Align Text </p>
                                            <FormControlLabel className='radio-chk' value="flex-start" control={
                                                <Radio onChange={ () => textPositionChange( 'flex-start', index ) } /> }
                                                label="Top" />
                                            <FormControlLabel className='radio-chk' value="center" control={
                                                <Radio onChange={ () => textPositionChange( 'center', index ) } /> }
                                                label="Middle" />
                                            <FormControlLabel className='radio-chk' value="flex-end" control={
                                                <Radio onChange={ () => textPositionChange( 'flex-end', index ) } /> }
                                                label="Bottom" />
                                        </RadioGroup>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-sm-12 pt-3'>
                                      <div className='image-text--background w-50' >
                                            <TextField type="color" id="outlined-basic" name="bg_color" label="Background Color" variant="outlined" value={el?.bg_color || '#414141'} onChange={(e)=>handleBgColorChange(e,index)} />
                                       </div>
                                    </div>
                                </div>
                                <div className='card-prop-description'>
                                    <label className='label'>Content</label>
                                    <RichTextEditor data={ el.description } eventHandler={ ( e ) => eventHandlers( e, index ) } />
                                </div>
                            </AccordionDetails>
                        </Acc>
                    } )
                }
                <div className='add-new-slide' title={ 'Add Card' } onClick={ addAccordion }><AddCircle className='add-card' /> Add New Card</div>

            </div>
        </div>
    )
}

HalfImageTextPanel.propTypes = {
    /** handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ).  */
    handleCeAssets : PropTypes.func,
}

export default HalfImageTextPanel
