import React, {useState} from 'react'
import PropTypes from 'prop-types'
import './videocontenttext.scss'
import Close from '@material-ui/icons/Close';

const VideoContentText = ({contentText, close}) => {
    const [render,setRender] = useState(true);
    const closeRenderArea = () =>{
        close();
        setRender(false)
    }
    return (
        <div className='video-content-text-container'>
            {render && <div className='overlay-context-text'>
                <div className='overlay-context-text-render'></div>
                <div className="add-on-context-text-overlay" >
                    <div className="head-item">
                        <button className="close-btn non-active-btn br-5" onClick={() => closeRenderArea()}><Close /></button>
                    </div>
                    {contentText && <div className="content-text-item" dangerouslySetInnerHTML={{__html: contentText ||'' }}></div>}
                </div>
            </div> }
        </div>
    )
}

VideoContentText.propTypes = {
    contentText: PropTypes.string
}

export default VideoContentText
