import LZUTF8_LIGHT from 'lzutf8-light';
import * as moment from 'moment-js'
import BuildHelper from '../../utils/BuildHelper';

/** SCORM 1.2 */

class SCORMWrapper {

    constructor(flatten) {
        // console.log(moment,moment(new Date()).milliseconds(),"time",moment().time().)
        this.scormobj = { // Reference object
            ll: { cdates: [new Date(), new Date(), new Date()] },
            ttime: 1000,
            tspent: 0,
            percentage: 0,
            suspend: '',
            ls: '',
            smax: 100,
            smin: 0,
        }
        this.flatten = flatten;
        this.findAPITries = 0;
        this.intialised = false;
        this.api = this.findAPI(window) || this.findAPI(window.opener);
        if ( (this.api == null) && (window.opener != null) && (typeof(window.opener) != "undefined") )
		{
		// try to find the API in the current window’s opener
		this.api  = this.findAPI(window.opener);
		}
    }

    async initialze() {
        try {
            if (this.api) {
                this.intialized = true;
                await this.api.LMSInitialize('', '');
            }
        } catch (err) {
            console.error(err);
        }
    }



    findAPI(win) {
        // Check to see if the window (win) contains the API
        // if the window (win) does not contain the API and
        // the window (win) has a parent window and the parent window
        // is not the same as the window (win)
        if(win){
            while ((win.API == null) && (win.parent != null) && (win.parent != win)) {
                // increment the number of findAPITries
                this.findAPITries++;
    
                // Note: 7 is an arbitrary number, but should be more than sufficient
                if (this.findAPITries > 7) {
                    console.debug("Error finding API -- too deeply nested.");
                    return null;
                }
    
                // the variable that represents the window being
                // being searched to be the parent of the current window
                // then search for the API again
                win = win.parent;
            }
            return win.API;
        }
        
    }

    getAPI() {
        // start by looking for the API in the current window
        var theAPI = this.findAPI(window);

        // if the API is null (could not be found in the current window)
        // and the current window has an opener window
        if ((theAPI == null) && (window.opener != null) && (typeof (window.opener) != "undefined")) {
            // try to find the API in the current window’s opener
            theAPI = this.findAPI(window.opener);
        }
        // if the API has not been found
        if (theAPI == null) {
            // Alert the user that the API Adapter could not be found
            //console.debug("Unable to find an API adapter");
        }
        return theAPI;
    }

    setSuspendData(value) {
        this.setScormValues('cmi.suspend_data', String(value));
    }

    setStudentName(name) {
        this.setScormValues('cmi.core.student_name', name);
    }

    setScoreRaw(score) {
        console.debug("score", score);
        this.setScormValues('cmi.core.score.raw', score);
    }
    setScoreMax(score) {
        this.setScormValues('cmi.core.score.max', score);
    }

    setScoreMin(score) {
        this.setScormValues('cmi.core.score.min', score);
    }
    setSessionTime() {
        let sessionTime = this.convertMillisecondsToScormTime(Number(this.scormobj?.ll?.totaltime || 1000));
        this.setScormValues('cmi.core.session_time', sessionTime);
    }
    /* topicNodeId*/
    setLastAccessedTopic(topicId) {
        let data = this.setLessonLocation(topicId, 1);
        this.setScormValues('cmi.core.lesson_location', data);
    }
    setTotalTime(time) {
        let data = this.setLessonLocation(time, 0);
        this.setScormValues('cmi.core.lesson_location', data);
    }
    setPercentage(percentage) {
        let data = this.setLessonLocation(percentage, 6);
        this.setScormValues('cmi.core.lesson_location', data);
    }
    setBookmarks(bids) {
        let data = this.setLessonLocation(bids, 2);
        this.setScormValues('cmi.core.lesson_location', data);
    }
    setAcknowledgements(acdata) {
        let lcdata = ''
        let data = this.setLessonLocation(lcdata, 3);//'00^'+lcdata+'^bmid^ackdata';
        this.setScormValues('cmi.core.lesson_location', data);
    }
    removeBookmarks(bids) {
        let data = this.setLessonLocation(bids, 4);
        this.setScormValues('cmi.core.lesson_location', data);
    }

    setLessonLocation(value, item) {
        try {
            /**
            lesson location string format
            ["60982", "2.3.3.2", "1.1.1,2.1.1,2.2.2", "", ""]
                    totaltime^lastplayedtopic^bookmark_ids^acknowledgement^all dates
            cases 0 			1				2				3			    4
            case 4 : bookmark delete
    
            **/
            let ll_string = '';
            if (this.scormobj.ll) {
                switch (item) {
                    case 0:
                        this.scormobj.tspent = value || 1000;
                        this.scormobj.ttime = value || 1000;
                        // this.scormobj.ttime.add(value, 'milliseconds');
                        this.scormobj.ll.totaltime = value;
                        this.scormobj.ll.ttime = value;
                        this.scormobj.ll.tspent = value;
                        ll_string = this.getLessonLocationString()
                        break;

                    case 1:
                        this.scormobj.ll.topic = value || '1.1';
                        ll_string = this.getLessonLocationString()
                        break;

                    case 2:
                        this.scormobj.ll.bmids.push(value);
                        ll_string = this.getLessonLocationString()
                        break;

                    case 3:
                        this.scormobj.ll.ackdata = value;
                        ll_string = this.getLessonLocationString()
                        break;

                    case 4:
                        this.scormobj.ll.bmids = [];
                        for (var i = 0; i < value.length; i++) { this.scormobj.ll.bmids.push(value[i]); }
                        ll_string = this.getLessonLocationString()
                        break;

                    case 5:
                        if (this.scormobj.ll.totaltime) {
                            this.scormobj.ll.cdates = value;
                            ll_string = this.getLessonLocationString()
                        }
                    case 6:
                        let cPercentage = value.split('^')
                        this.scormobj.percentage = Number(cPercentage[cPercentage.length -1]);
                        ll_string = this.getLessonLocationString()
                        break;

                }



            }

            return ll_string;
        } catch (err) {
            console.log('Error in SetLessonLocation ==========>', err);
        }
    }

    getLessonLocationString() {
        // return this.scormobj.progress;
        return String(this?.scormobj?.ll?.totaltime!== undefined ? this?.scormobj?.ll?.totaltime : "1000") + '^' + (this?.scormobj?.ll?.topic || '') + '^' + (this?.scormobj?.ll?.bmids || '')?.toString() + '^' + (this?.scormobj?.ll?.ackdata || '') + '^' + this.stringifyDates(this?.scormobj?.ll?.cdates) + '^' + this?.scormobj?.percentage;
    }

    stringifyDates(dlist) {
        let d, date, month, year;
        let dstr = "";
        let df = "";
        for (let i = 0; i < dlist.length; i++) {

            d = dlist[i];
            date = d.getDate();
            month = d.getMonth() + 1;
            year = d.getFullYear();

            if (month < 10) {
                month = '0' + month;
            }
            if (date < 10) {
                date = '0' + date;
            }

            df = date + '' + month + '' + String(year).substring(2);
            dstr += df;

        }
        return dstr;
    }



   async setLessonStatus(code) {
        let current_status = this.getLessonStatus;
        let status = ['passed', 'completed', 'failed', 'incomplete', 'browsed', 'incomplete'];
        let pstatus = ['passed', 'passed', 'failed', 'unknown', 'unknown', 'unknown'];
        if (current_status === 'completed' && status[code] === 'completed') {
            return;
        }
        if (current_status !== 'incomplete') {
            this.setScormValues('cmi.core.lesson_status', status[code || 1]);
        }
        if(code === 1){
            this.setScormValues('cmi.core.success_status', status[0]);
        }
        // await this.api.LMSCommit('');
    }
    convertMillisecondsToScormTime(totalMilliseconds) {
        try {
            // Validate input
            if (typeof totalMilliseconds !== 'number' || isNaN(totalMilliseconds)) {
                throw new Error('Invalid input');
            }
    
            // Convert milliseconds to total seconds
            let totalSeconds = totalMilliseconds / 1000;
    
            // Convert seconds to hours, minutes, and seconds
            let hours = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds % 3600) / 60);
            let seconds = (totalSeconds % 60).toFixed(2);
    
            // Format time to HH:MM:SS.SS
            let scormTime = String(hours).padStart(2, '0') + ":" +
                            String(minutes).padStart(2, '0') + ":" +
                            String(seconds).padStart(5, '0');
    
            return scormTime;
        } catch (error) {
            console.error('Error in converting time:', error.message);
    
            // Default to 10 minutes in case of failure
            return "00:10:00.00";
        }
    }
    async setLmsFinish() {
        if (this.api) {
            await this.api.LMSFinish('');
        }
    }

    async setScormValues(param, data) {
        try {
            if (this.api) {
                console.log('setScormValues', 'Testing', param, data);
                await this.api.LMSSetValue(param, String(data));
                await this.api.LMSCommit('');
            }
        } catch (err) {
            console.error(err);
        }
    }

    getUserName() {
        let sname = this.getStudentName;
        if (sname) {
            let splited = sname.split(",");
            if (splited.length > 1) {
                if (splited[1] !== undefined && splited[0] !== undefined) {
                    sname = splited[1] + " " + splited[0]; // firstname and lastname
                } else {
                    sname = splited[1];
                }

            }
        } else {
            sname = "Test User"; // Default Test User
        }
        return sname;
    }
    getStudentName() { return this.getScormValues("cmi.core.student_name"); }
    getLessonStatus() { return this.getScormValues("cmi.core.lesson_status") }
    getSuspendData() { return this.getScormValues("cmi.suspend_data"); }
    getLessonMode() { return this.getScormValues("cmi.core.lesson_mode"); }
    getScoreRaw() { return this.getScormValues("cmi.core.score.raw"); }
    getScoreMax() { return this.getScormValues("cmi.core.score.max"); }
    getScoreMin() { return this.getScormValues("cmi.core.score.min"); }

    getLessonLocation() {
        try {
            let location = this.getScormValues("cmi.core.lesson_location");
            if (location) {
                this.scormobj.ll = location;
                let lsplit = this.scormobj.ll.split('^');
                this.scormobj.ttime = Number(lsplit[0]);
                this.scormobj.ll = { totaltime: lsplit[0] !== undefined ? Math.floor(lsplit[0]) : 1000 ,  tspent: lsplit[0] !== undefined ? Math.floor(lsplit[0]) : 1000 , ttime: lsplit[0] !== undefined ? Math.floor(lsplit[0]) : 1000 , topic: lsplit[1], bmids: lsplit[2].split(','), ackdata: lsplit[3], cdates: this.parseDate(lsplit[4]), percentage: this.getCompletedPercentage(lsplit[5]) };
                return this.scormobj.ll;
            }
            return location;
        } catch (err) {
            console.log(err);
            console.log('********* Error in GetLessonLocation ************>', err);
        }
    }
    getRawLessonLocation() {
        try {
            let location = this.getScormValues("cmi.core.lesson_location");
            return location;
        } catch (err) {
            console.log(err);
            console.log('********* Error in GetLessonLocation ************>', err);
        }
    }
    getCompletedPercentage(ccp) {
        if (!isNaN(Number(ccp))) {
            this.scormobj.percentage = Number(ccp);
            return this.scormobj.percentage;
        }
        return this.scormobj.percentage;
    }

    parseDate(dates) {
        /*
        * Assuming 	0 = Course assigned
        *			1 = Course Started
        *			2 = Last Accessed
        *			3 = Completed
        * if dates are not present setting all the date to
        * current date : TODO CHECK WITH SCORM
        * keeping only for review
        */
        var dlist = [];
        var i;
        if (!dates && window.API === undefined) {
            for (i = 0; i < 3; i++) {
                dlist.push(new Date());
            }
            return dlist;
        }

        if (dates && dates.length === 6) {
            dlist.push(this.createDate(dates));
        }
        else if (dates && dates.length === 18) {
            for (i = 0; i < 3; i++) {
                dlist.push(this.createDate(dates.substring(i * 6, (i * 6) + 6)));
                // console.log( dates.substring(i*6, (i*6)+6 ));
            }
        }
        else if (dates && dates.length === 24) {
            for (i = 0; i < 3; i++) {
                dlist.push(this.createDate(dates.substring(i * 6, (i * 6) + 6)));
                // console.log( dates.substring(i*6, (i*6)+6 ));
            }
        }
        else {
            // for(var i=0; i<3; i++)	{
            // 	dlist.push( new Date() )
            // }
        }
        return dlist;
    }



    createDate(d) {
        return new Date(Number('20' + d.substring(4)), Number(d.substring(2, 4)) - 1, Number(d.substring(0, 2)));
    }

    getScormValues(param) {
        try {
            if (window.API) {
                // window.API.LMSGetValue(cmi.suspend_data)
                console.log("window.API.LMSGetValue(param)",window.API.LMSGetValue(param))
                let valueSco = window.API.LMSGetValue(param);
                return valueSco
            } else {
                window.API = this.getAPI();
                if(!window.API || window.API === undefined )	{
                    window.API = window?.opener?.API;
                }
                console.log('window API Not Found...')
            }
        } catch (err) {
            console.log(err)
        }
    }
    /**
     * Scorm data manipulations
     * String format of progress data 
     * entryption and decreption
     */

    getScormData = () => {
        return this.getSuspendData()
    }
    setScormData = (value) => {
        this.setSuspendData(value)
    }


    ignoreDots = (id) => {
        const tpslt = id.split('.');
        const isDeci = tpslt.find(i => { return (i.toString().length > 1) })
        return (isDeci ? id : tpslt.join(''))
    }
    addDots = (id) => {
        if (!id) { return '' }
        return (id.indexOf('.') > 0 ? id : (id.split('').join('.')))
    }
    /**
     * Changes made on 4-7-24:
     * Reason: Due to i18n integration, we are placing the selected language inside the SCORM progress.
     * We have modified the functions to include the language code (ln) in the encrypted and decrypted progress.
     */
    encryptProgress = (tracks) => {
        try {
            let pgr = '';
            tracks?.topic?.forEach(i => {
                let sts = i?.status < 0 ? 9 : i?.status;
                let tp = `${sts}|${i.percent}|${this.ignoreDots(i.flatId)}`;
                let cmp = [];
                i.content.forEach(j => {
                     // Base64 encode the state string to handle special characters
                    let stateStr = Buffer.from(JSON.stringify(j?.state || {})).toString('base64');
                    cmp.push(`${j.id}^${j.status}^${stateStr}`);
                });
                let cmpstr = `-[${cmp.toString()}]`;
                pgr += `${tp}${cmpstr}*`;
            });
            // Include the language code (ln) in the progress string
            const lan = `|^ln-${tracks?.lan}`;
            const fullString = `${pgr}${lan}`;
    
            const output = LZUTF8_LIGHT.compress(fullString);
            return output;
        } catch (e) {
            console.error(e);
            return null;
        }
    }
    



    /**
     * Changes made on 4-7-24:
     * Reason: Due to i18n integration, we are placing the selected language inside the SCORM progress.
     * We have modified the functions to include the language code (ln) in the encrypted and decrypted progress.
     */
    decryptProgress = (output) => {
        let progress = [];
        try {
            const deoutput = LZUTF8_LIGHT.decompress(output);
            const sections = deoutput.split('|^');
            
            if (sections.length < 1) {
                throw new Error("Invalid input format: Not enough sections");
            }
    
            const topicSection = sections[0];
            const lnSection = sections[1];
    
    
            // Process topics
            const tpList = topicSection?.split('*');
            tpList.forEach(i => {
                if (i.length <= 0) { return }
                let [tps, tpt] = i.split('-');
                let tpsEle = tps.split('|');
                let tpObj = {
                    flatId: this.addDots(tpsEle[2]),
                    topicId: '',
                    content: [],
                    percent: tpsEle[1] || 0,
                    status: (Number(tpsEle[0]) === 9 ? -1 : Number(tpsEle[0])) || 0,
                    timestamp: Date.now()
                };
                let content = [];
                let cntStr = tpt?.substring(1, tpt?.length - 1) || [];
                if (cntStr.length === 0) { tpObj.content = content; progress?.push(tpObj); return }
                let cntSplt = cntStr?.split(',');
                cntSplt?.forEach(j => {
                    let [id, status, stateStr] = j?.split('^');
                    // Decode the Base64 encoded state string
                    let decodedStateStr = stateStr ? Buffer?.from(stateStr, 'base64')?.toString() : {};

                    let comSts = {
                        id: id,
                        status: Number(status),
                        state: Object.keys(decodedStateStr)?.length > 0 ? JSON.parse(decodedStateStr) : {}
                    };
                    content?.push(comSts);
                });
                tpObj.content = content;
                progress.push(tpObj);
            });
            // Extract and return the language code (ln) along with the topic progress
            let ln = lnSection?.split('-')[1];
            if(ln==='null')ln=null;
                return {
                topic: progress,
                lan: ln,
            };
        } catch (e) {
            console.error('Error in decrypting progress from SCORM :: ', e);
            return null;
        }
    }
    
    




}
export default SCORMWrapper

