import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import CeAssets from'../../../assets/img/CeAssets.png';
import DataHelper from '../../../utils/DataHelper';

/**
 * 
 * @param {*} topic 
 * @returns current topic selectedd by user
 */

/**
 * 
 * @param {*} notes 
 * @returns array of images releated to ce notes 
 */

/**
 * Poster contains the feed images as content
*/
const Poster = ({topic, notes}) => {

    const [src, setSrc] = useState({})

    useEffect(() => { 
        if(notes?.length > 0) {
            setSrc({path: notes[0].content?.path, title: notes[0]?.content?.title, description: notes[0]?.content?.description})
        }
    }, [notes,topic])

    const handleImage = (e, {path, title, description}) => {
        setSrc({path, title, description})
        const list = document.querySelectorAll(`.ce-notes-list-images .image-tag.active`)
        list.forEach((el) => el?.classList.remove('active'))
        if(e.target?.alt === title) {
            e.target?.classList.add('active')
        }
    }
    return (
        <div className={`ce-container row ${topic === 'CE Notes' ? 'ce-show': ''}`}> 
            <div className='col-sm-8 col-md-8'>
                <div className='ce-image-main'>
                    {(notes?.length > 0 && src?.path) ?<img src={DataHelper.getSupplementarieFilePath(src?.path)}  alt={src?.title}  onError={(e)=>{e.target.onerror = null; e.target.src=CeAssets}}/> : <h3 className='no-supplimentarie'>No New Notes</h3>}
                    <p className='inner-content' dangerouslySetInnerHTML={{__html: src?.description}}></p>
                </div>
            </div>
            <div className='col-sm-4 col-md-4'>
                <div className='ce-notes-image-list'>
                    {notes?.length > 0 && notes?.map((el,index) => <div className='ce-notes-list-images' key={index}>
                    <button onClick={(e) => handleImage(e,el?.content, index)} className='non-active-btn'><img src={DataHelper.getSupplementarieFilePath(el?.content?.path)}  alt={el.content?.title} onError={(e)=>{e.target.onerror = null; e.target.src=CeAssets}} className={'image-tag'}/></button> 
                    </div>) }
                </div>
            </div> 
        </div>
    )
}

Poster.propTypes = {
    /** Topic of current section */
    topic: PropTypes.string,
    /** Notes will contain array of images related to ce notes */
    notes: PropTypes.array
}

export default Poster
