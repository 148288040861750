import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import './draggables.scss';
import DataHelper from '../../../utils/DataHelper';
import AudioPlayer from "react-h5-audio-player";
import { getPlaceHolderImg } from "../../../utils/MediaConfig";
import MuiIcons from '../../ui/MuiIcons/MuiIcons'
import AppButton from '../../ui/AppButton/AppButton';
import t from '../../../translation/useTranslate';

/**
 * 
 * @param {*} bucket 
 * @returns sequence list of string bucket
 */

/**
 * 
 * @param {*} drags 
 * @returns object contains array of drags in each object items
 */


/**
 * 
 * @param {*} question 
 * @returns  string it will contain the question
 */

/**
 * Draggables component is used for items to drag and put it one box items 
 * Mainly used for some assessment component 
*/
const Draggables = (props) => {
    const { question, head, subhead, explanation, bucket, drags, enable_voice, enable_strict,feedback, track, handler, instructions } = props;

    const [status, setStatus] = useState({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
    const [instruction, setInstruct] = useState(instructions)
    const [dragCount, setDragCount] = useState([]);
    const [dragItems, setDragItems] = useState(drags);
    const [DropContainer, setDropContainer] = useState(bucket);
    const [currentDrag, setCurrentDrag] = useState(null);
    const [score, setScore] = useState({isCom:false,hasRight:0,data:[]});
    const player = useRef();
    const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
    const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS;

    useEffect(() => {
        reset();
    }, [bucket,drags, enable_voice, enable_strict, feedback])

    /* drop clean up after refresh */
    // useEffect(() => {
    //     reset();
    // }, [])

    const reset =()=>{
        try{
            setDragItems(DataHelper.shuffle(drags));
            setDropContainer(bucket);
            setDragCount([]);
            setCurrentDrag(null);
            setScore({isCom:false,hasRight:0,data:[]});
            // isDragComplete = dragCount.length === drags.length;
            // isScore = score?.hasRight;
            DropContainer.forEach(e => {
                e?.container?.forEach((drags)=> drags.containerElem.style.display = 'block')
                e.container = [];
            });
        }catch(e){}
    }


    const dropped = (draggedElement, targetIndex) => {
        try {
    
            // Filter out the dragged element from all other containers
            const updatedContainers = [...DropContainer];
            let hasReDropIndex = updatedContainers[targetIndex].container.findIndex(
                    element => element?.dragData?.id === draggedElement.dragData?.id
                );
    
            // Check if the dragged item is already in the target container
            const isAlreadyInTarget = updatedContainers[targetIndex]?.container?.findIndex(
                element => element?.dragData?.id === draggedElement.dragData?.id
            ) !== -1;
    
            // Check if the dragged item is dropped in the correct target
            const isDroppedInCorrectTarget = draggedElement.dragData?.target === DropContainer[targetIndex]?.id;
    
            if ((isDroppedInCorrectTarget || !enable_strict) && !isAlreadyInTarget && hasReDropIndex===-1) {
                // Create a deep clone if needed, otherwise update the original data
                const newContainers = updatedContainers;
                newContainers[targetIndex].container.push(draggedElement);
    
                // Update the state
                setDropContainer([...newContainers]);
                draggedElement.containerElem.style.display = 'none';
    
                // Update drag count
                setDragCount(dragCount);
    
                // Set current drag with feedback
                let activeDraggedElement = {
                    ...draggedElement?.dragData
                };
    
                if (isDroppedInCorrectTarget && draggedElement?.dragData?.feedback) {
                    activeDraggedElement.explanation = draggedElement?.dragData?.feedback[0];
                    activeDraggedElement.isRight = true;
                } else if (draggedElement?.dragData?.feedback && draggedElement?.dragData?.feedback[1]) {
                    activeDraggedElement.explanation = draggedElement?.dragData?.feedback[1];
                }
                setCurrentDrag({ ...activeDraggedElement });
    
                // Update the score if the item is dropped in the correct target
                let newScoreData = [...(score?.data || [])];
                const isInScoreData = newScoreData.findIndex(e => e.id === draggedElement?.dragData?.id);
    
                if (isDroppedInCorrectTarget && newScoreData.length <= drags?.length && isInScoreData === -1) {
                    newScoreData.push(draggedElement?.dragData);
                } else if (isInScoreData !== -1) {
                    newScoreData.splice(isInScoreData, 1);
                }
    
                const updatedScore = {
                    ...score,
                    isCom: dragCount?.length === drags?.length,
                    data: newScoreData,
                    hasRight: newScoreData.length === drags?.length
                };
                setScore({ ...updatedScore });
            }
        } catch (error) {
            console.error("Error in dropped function: ", error);
        }
    };
    
    
    



    /**
      * Manage the component update 
      * progress logic in this method
      * 
      * Update the view status when ever the user interacts
      * Update the progess status ONLY ONCE, when status completes
      * 
      */
    const updateProgress = (dropedItems) => {

        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c: dropedItems }
        /**
         * Progress updated as and when its completed
         */

        if (!track.status) {

            track.status = 1
            setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
            if (track.status) {
                handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: props.cename })
            }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        handler({ type: UPDATE_STATUS, id: track.id, name: props.cename })

    }

    const onDropped = (e) => {
        const isCheck = dragCount.findIndex((el) => el?.id === e?.dragData?.id);
        let newDragCount = [...dragCount]; // Create a copy of dragCount to avoid mutating state directly
    
        if (isCheck === -1) {
            newDragCount.push(e?.dragData);
        }
    
        setDragCount(newDragCount);
    }

  /* status updating  */
    useEffect(() => {
        setDropContainer(DropContainer);
        if (currentDrag?.audio && enable_voice) {
            setTimeout(() => {
                player.current.audio.current.play();
            }, [1000])
        }
        if (score?.isCom) updateProgress((score?.data?.length||0));
        else if(!status?.completed){
            setInstruct({
                ...instruction,
                text: `You have completed this interactivity, you can proceed to next section`,
                className: 'completed'
            });
        }
    }, [score, dragCount ,currentDrag])

    const getBgImageUrl = (path) => {
        return DataHelper.getResourcePath(0, path);
    }

    const getAudioUrl = (path) => {
        return DataHelper.getResourcePath(3, path);
    }

   /* handel audio */
    if (enable_voice) {
        let auplayer = player?.current?.audio?.current;
        try {
            auplayer.onended = function () {
                setCurrentDrag(null)
            }
        } catch (e) { }
    }


    const drapedItem=(dropped,eIndex,isRight)=>{
        return (isRight || !enable_strict) && <div className={`item ${ (isRight || !score?.isCom) ? 'right-ans': 'wrong-ans'}`}key={eIndex}>
        {dropped?.image && <div className='image-box'>
            <img className='drag-item-img' src={getBgImageUrl(dropped?.image)} alt='drag-item' onError={(e) => { e.target.onerror = null; e.target.src = getPlaceHolderImg(200) }} />
        </div>}
        <div className='drag-item-text' key={eIndex}>{dropped?.text}</div>
    </div>  
    }

    const drapedDragItem=(item,index,isRight)=>{
        let cTarget= (currentDrag?.audio && enable_voice) ? -1 : (!enable_strict ? 'quiz' : item?.target)
        return <DragDropContainer
        key={index}
        targetKey={(currentDrag?.audio && enable_voice) ? -1 : cTarget }
        dragData={item}
        onDrop={(e) => onDropped(e)}
        id={`drag_${index}`}
        >
         <div className={`item ${ (isRight) ? 'right-ans': 'wrong-ans'}`}key={index}>
                    {item?.image && <div className='image-box'>
                        <img className='drag-item-img' src={getBgImageUrl(item?.image)} alt='drag-item' onError={(e) => { e.target.onerror = null; e.target.src = getPlaceHolderImg(200) }} />
                    </div>}
                    <div className='drag-item-text' key={index}>{item?.text}</div>
                </div>
        </DragDropContainer>
   
    }
   
    
    return <>
        <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text} />
        <div className='main-dragging-container'>
            <caption className='dragging-title pt-2 '>{question}</caption>
            {/*  after complete feedback */}
            {(feedback && currentDrag && currentDrag?.explanation?.length > 0 )&& <div className='dragging-result m-0'> <p className={`${currentDrag?.isRight ? 'right-ans' : 'wrong-ans' } m-0 fade`}> <div dangerouslySetInnerHTML={{ __html: currentDrag?.explanation }}/> </p></div>}
            {score?.isCom &&  <div className='dragging-result fade pt-3 m-0'>
                {!(score?.hasRight) ? <div className='wrong-box '>
                <span className={`wrong-ans`}> <div dangerouslySetInnerHTML={{ __html: explanation?.wrong }}/> </span>
                < AppButton size="small" className="mx-2" theme="primary1_transparent" onClick={reset} >
                   <MuiIcons iconName='re_start'/> {t("Reset")}
                </AppButton >
                </div> : 
                <span className={`right-ans `}><div dangerouslySetInnerHTML={{ __html: explanation?.right }}/> </span>}
             </div>}
            <div className="dragging-container">
                <div className='drag-items-container'>
                    {/* after drop audio is played at background */}
                    {(enable_voice && currentDrag?.audio) && <div className='drop-audio'>
                        <AudioPlayer
                            src={getAudioUrl(currentDrag?.audio)}
                            autoPlayAfterSrcChange={true}
                            preload='metadata'
                            muted={false}
                            ref={player}
                            loop={false}
                            volume={0.5}
                        />
                        <p className='required-audio'>Audio is required to complete this section of the training</p>
                    </div>}
                    {dragItems?.length > 0 && dragItems?.map((el,index) => {
                        let cTarget= (currentDrag?.audio && enable_voice) ? -1 : (!enable_strict ? 'quiz' : el?.target)
                        return <DragDropContainer
                            key={`${enable_strict ? el?.id : 'quiz'}-${index}`}
                            targetKey={(currentDrag?.audio && enable_voice) ? -1 : cTarget }
                            dragData={el}
                            onDrop={(e) => onDropped(e)}
                        >
                                <div className='item' >
                                    {el?.image && <div className='image-box'>
                                        <img className='drag-item-img' src={getBgImageUrl(el?.image)} alt='drag-item' onError={(e) => { e.target.onerror = null; e.target.src = getPlaceHolderImg(200) }} />
                                    </div>}
                                    <div className='drag-item-text'>{el?.text}</div>
                               </div>
                        </DragDropContainer>
                    })}
                </div>
                <div className={`drop-continuer ${DropContainer?.length===1 && 'single-drop'}`}>
                    {DropContainer?.length > 0 && DropContainer?.map((el, index) => {
                        let dropClass = DropContainer[index]?.container?.length > 0 ? 'drop-items' : null;
                        let target=!enable_strict ? 'quiz' : el?.id;
                        return <DropTarget
                            key={`${enable_strict ? el?.id : 'quiz'}-${index}`}
                            targetKey={target || -1}
                            onHit={(e) => dropped(e, index)}
                            className={`doped-itm-${index}`}
                        >
                            <div className='drop-items-container' id={`doped-itm-${index}`}>
                                <p className={`drop-box ${dropClass}`}>{el?.text} </p>
                                {/* drag items inside the drop box start */}
                                <div className='dropped-items' >
                                    {el?.container?.length > 0  && el?.container?.map((dragData,eIndex) => {
                                        let dropped=dragData?.dragData;
                                        let isRight= dropped?.target === el?.id;
                                        return (feedback && !isRight) ? drapedDragItem(dropped,eIndex,isRight) : drapedItem(dropped,eIndex,isRight)
                                    })}
                                </div>
                                {/* drag items inside the drop box start */}
                            </div>
                        </DropTarget>
                    })}
                </div>
            </div>
        </div>
    </>
}


Draggables.defaultProps = {
    instructions: {
        text: 'Drag & Drop the icons to the appropriate location, Mouse over icon for more information',
        enabled: false
    }
}


Draggables.propTypes = {
    /** bucket will have number of drag objects  */
    bucket: PropTypes.array,
    /** drags items will be object contains list of items for drag and drop */
    drags: PropTypes.array,
    /** enable_voice manage the voice option */
    enable_voice: PropTypes.bool,
    /** string it will contain the question  */
    question: PropTypes.string,
    /** head it will contain the head question  */
    head: PropTypes.string,
    /** subhead it will contain the subhead question  */
    subhead: PropTypes.string,
    /** explanation it will contain the question  explanation */
    explanation: PropTypes.object,
    /** Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler: PropTypes.func
}

export default Draggables