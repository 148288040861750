import React, {useEffect,useState} from 'react'
import { SnippetDesc, ContainerHead, Imgtag, Links } from './styles'
import './halfimagetext.scss'
import PropTypes from 'prop-types'
// import { pathCreater } from '../../../utils/MediaConfig'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ContentTracking from "../../../core/progress/ContentTracking";
// import Tooltip from '@material-ui/core/Tooltip';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction'
import DataHelper from '../../../utils/DataHelper';
import ImageTag from '../../ui/ImageTag';

const debouncing = (selector, callback, isLongContent) => {
    const isView = isLongContent || null
    setTimeout(() => {
        let element = document.querySelector(selector);
        let position = element?.getBoundingClientRect();
        const isMobile = (window.matchMedia('max-width: 768px').matches || window.matchMedia('max-height: 450px').matches)
            // checking for partial visibility
            if((position?.top < window.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
            callback()
        } else {
            // checking whether fully visible
            if(position?.top >= 0 && position?.bottom <= window.innerHeight) {
                callback()
            }
        }
    
    }, 100)
}

const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS;
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

/**
 * 
 * @param {array} items 
 * @returns image and textcontent in array of each item
 */

/**
 * 
 * @param {string} cename 
 * @returns name of component
 */

/**
 * HalfImagetext component contains one side text and Image
*/
const HalfImageText = ({ items,track,handler,cename,textPosition, tp_status }) => {
    const contentLayout = document.querySelector('.content')
    const [status, setStatus] = React.useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})
        /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
      const updateProgress = () => {

        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c:1}
        /**
         * Progress updated as and when its completed
         */

        if (!track.status) {
          track.status = 1;
                    if (track.status) {
            setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
            handler({ type: UPDATE_STATUS, id: track.id, name:cename })
          }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        // handler({ type: UPDATE_STATUS, id: track.id, name:cename })
    
    }

        
    useEffect(()=>{
        if(tp_status > -1) updateProgress();
    },[tp_status])
 
    // componentObserver('section[name=HalfImageText]', updateProgress)
    contentLayout.addEventListener('scroll', ()=> {
        DataHelper.scrollCompletionDebouncing('section[name=HalfImageText]', updateProgress, true)
    })

    const getImageUrl = (path)=>{
        return DataHelper.getResourcePath(0,path);
    }
   
    const hasBottomImage=(el)=>{
        if(!el?.isHalf && el?.textPosition==='flex-end'){
            return  {flexDirection: 'column-reverse'}
        }
        return {}
    }

    return (
        <>
      <Instruction isInstruction={false} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`}/>
            <div className={`image-card-div`}>
                {items?.length > 0 &&  items?.map(el => <ContainerHead className={`layout image-text ${!el.isHalf ? 'no-shadow': ''}`} key={el.id}>
                    <div className={`${(el.isImageRight||!el?.isHalf ) ? 'row':'row-reverse '} ${(!el?.isHalf) && 'center-img'}`}  style={{background:el?.bg_color || 'none',alignItems:(el?.textPosition||'center'),...hasBottomImage(el)}}>
                        <div className={`${el.isHalf ? 'col-md-6 col-lg-6 ' : 'col-md-12 col-lg-12'} col-sm-12 col-12 img-section`}>
                            <div className='image-main'>
                                {el?.imageUrl && 
                                 <ImageTag
                                 img={el?.imageUrl || "*"}
                                 bgColor={el?.color || "rgba(0, 0, 0, 0)"}
                               />
                                // <Imgtag loading='lazy' src={getImageUrl(el.imageUrl)}
                                // //onError={(e)=>{e.target.onerror = null;
                                // //e.target.src=getPlaceHolderImg(600)} }
                                //   />
                                   }
                            </div>
                        </div>
                        <div 
                        style={{alignItems:(el?.textPosition||'center')}}
                        className={`${el.isHalf ? 'col-md-6 col-lg-6 ' : 'col-md-12 col-lg-12 half-section '} col-sm-12 col-12 custom-text-position text-section`}
                        >
                            {/* <h2 className='title-head'>{el.title}</h2> */}
                            <SnippetDesc className='text-content' dangerouslySetInnerHTML={{__html: el.description}}>
                            </SnippetDesc>
                            {el.link ? <Links className={`${!el.isHalf ? 'half': ''}`}>Click Here <ArrowForwardIcon /></Links> : ''}
                        </div>
                    </div>
                </ContainerHead>)  }
            </div>
            </>
    )
}

HalfImageText.propTypes = {
    /** Items will include image url, content text and isHalfImage flag whether it should be half or not */
    items: PropTypes.array.isRequired,
    /** Tracking the component progress */
    track:PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler:PropTypes.func
}

export default HalfImageText
