import React, { useState, useEffect } from "react";
import Datahelper from "../../../utils/DataHelper";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import UploaderPanel from "../UploaderPanel/UploaderPanel";
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import ACLHelper from "../../../utils/ACLHelper";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CustomCheckbox from '../../../components/ui/CustomCheckbox/CustomCheckbox';
import Slider from '@mui/material/Slider';
import { useSelector } from 'react-redux';



/**
 * VidExploPanel is a React component that manages video exploration properties,
 * including video upload, poster selection, and accordion-based cue point management.
 *
 * @param {object} props - Props passed to the component
 * @param {function} handleProperties - Callback function to handle property updates
 * @param {function} handleCeAssets - Callback function to handle asset management
 * @param {string} id - Unique identifier for the panel
 */
const VideoHotspotPanel = ({ handleProperties, handleCeAssets, id, ...props }) => {
  const [data, setData] = useState({ ...props });
  const [expanded, setExpanded] = useState(null);
  const [isPoster, setIsPoster] = useState(["", "*"].includes(data?.poster || "") ? false : true);
  const { courseEdit: { isAddVideoSupplimentaries } } = useSelector((state) => state);

  useEffect(() => {
    handleProperties({ ...data });
  }, [data]);

  useEffect(() => {
    /** 
      * Check if video supplementaries are active, have a defined duration,
      * and are not in edit mode (-1 indicates not editing).
      */
    const isActive = isAddVideoSupplimentaries?.isActive;
    const hasDuration = isAddVideoSupplimentaries?.cduration;
    const isEditMode = isAddVideoSupplimentaries?.isEdit === -1;
    if (isActive && hasDuration && isEditMode) {
      const maxId = Math.max(...data?.items?.map(item => item?.id), 0)||1;
      const newCue = {
        id:maxId+1,
        type:0,
        point:[hasDuration,data?.duration],
        title:'New content',
        desc:'Detailed content follows'
      };
      const updatedCuePoints = [...(data?.items || []), newCue];
      setData((prevData) => ({
        ...prevData,
        items: [...updatedCuePoints],
      }));
 
      handleProperties({ ...data, items: [...updatedCuePoints] });
      setExpanded(updatedCuePoints.length - 1); 
    }else if (isAddVideoSupplimentaries?.isEdit !== -1) {
      const editTime = Number(isAddVideoSupplimentaries?.isEdit?.time);
      
      data.items?.forEach((cuepoint, index) => {
          if (Number(cuepoint?.point) === editTime) {
              setExpanded(index);
          }
      });
  }

  }, [isAddVideoSupplimentaries])

  /**
   * Handles accordion panel expansion
   * @param {string} panel - Panel identifier
   */
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  /**
   * Updates the list of accordion items
   * @param {Event} e - Event object
   * @param {Array} list - Updated list of items
   */
  const handleUpdateAccList = (e, list) => {
    let updated = { ...data, items: [...list] }
    setData({ ...updated,  items: [...list] });
  }
  /**
   * Updates a specific cue point in the items list
   * @param {Event} e - Event object
   * @param {number} index - Index of the cue point in the list
   */
  const eventUpdate = (event) => {
    const updated = { ...data }
    updated[event?.name] = event?.value || ''
    setData({ ...updated });
  }
  const introUpdate = (value) => {
    const updated = { ...data }
    updated['intro'] = value || ''
    setData({ ...updated });
  }

  const textUpdate = (key,value) => {
    setData((prv)=>{ 
      const updated = { ...prv }
      updated[key] = value || ''
      return{...updated} 
    });
  }

  /**
   * Handles updates to RichTextEditor content
   * @param {string} value - HTML content string
   * @param {number} i - Index of the item in the list
   */
  const eventHandlers = (value, i) => {
    let updatedItem=[...data?.items];
    updatedItem[i]["desc"] = value;
    setData({...data,items:[...updatedItem] });
  };


  const getResourceUrl = (path) => {
    return Datahelper.getResourcePath(1, path)
  }

  /** check the url is absolute or only video name is given */
  const getAbsUrl = (src) => {
    try {
      if (src)
        return src.startsWith('http') ? src : getResourceUrl(src)
    } catch (e) {
      console.log(e)
    }
  }

  const getVideoDuration = async (videoUrl) => {
    try {
      const duration = await new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = videoUrl; // set the video source
        video.preload = 'metadata'; // load only the metadata (including duration)

        // when metadata is loaded, resolve the promise with the duration
        video.onloadedmetadata = () => {
          resolve(video.duration); // return duration in seconds
        };

        // handle errors if video loading fails
        video.onerror = () => {
          reject('Error: Unable to load video or fetch metadata.');
        };
      });

      return duration;
    } catch (error) {
      console.error(error);
      return null; // return null or handle the error as you see fit
    }
  };

  const handleVideo = async (uploaded) => {
    try {
      const duration = await getVideoDuration(getAbsUrl(uploaded?.name));
      let prop = {
        ...data,
        src: uploaded?.name,
        duration: duration || ''
      }
      handleCeAssets(data?.src, uploaded.name, prop);
      setData(prop);
      handleProperties(prop);
    } catch (e) {
      console.debug(e);
    }
  }

  const handleIntroAudio = async (name,uploaded) => {
    try {
      setData((prev)=>{
        let previousState=prev;
        let updatedState={...prev, [name]:uploaded.name}
        handleCeAssets(previousState?.src, uploaded.name, updatedState);
        handleProperties(updatedState);
        return{
          ...updatedState
        }
      });
    } catch (e) {
      console.debug(e);
    }
  }


  const handleFirstFrame = (e) => {
    try {
      if (!e) {
        let prop = { ...data, poster: "*" }
        handleCeAssets(data?.poster, "*", prop);
        setData(prop)
      }
      setIsPoster(e);
    } catch (e) {
      console.debug(e);
    }
  }

  const handleForward = (e) => {
    let prop = { ...data, [e.target.name]: e?.target?.checked };
    setData({ ...prop });
  }


  const handlePoster = (uploaded) => {
    console.log("uploaded", uploaded);

    try {
      let prop = { ...data, poster: uploaded?.name }
      handleCeAssets(data?.poster, uploaded.name, prop);
      setData(prop)
    } catch (e) {
      console.debug(e);
    }
  }

  const handleHotpot = (e , index) => {
    const [start, end] = e?.target?.value.map(parseFloat);
    if ((end - start) <= 1) {
        return;
    }
    let updatedItem=[...data?.items]
    updatedItem[index]['point'] = e?.target?.value || [0,1]
    setData({ ...data, items:[...updatedItem] });
  }

  const validatePoint = (point, cIndex) => {
    // Check if data and items are defined
    if (!data || !data?.items || data?.items?.length === 0) {
      return { isNew: false, overlaps: false }; 
    }
  

    const startCurrentPoint = parseFloat(point[0]); 
    const endCurrentPoint = parseFloat(point[1]);
    let overlaps = false; 
    let pointIndex=''
  
    // iterate through items to check if the point is new or overlaps
    for (let index = 0; index < data.items.length; index++) {
      const el = data.items[index];
      const startExistingPoint = parseFloat(el.point[0]);
      const endExistingPoint = parseFloat(el.point[1]);
  
      // check if the new range overlaps with the existing range
      if (
        (startCurrentPoint < endExistingPoint && endCurrentPoint > startExistingPoint) &&
        cIndex !== index 
      ) {
        overlaps = true; 
        pointIndex=index
        break; 
      }
    }
   let message=`Another hotspot ( No: ${pointIndex+1} ) is overlapping with this, please check. `
    return {overlaps:overlaps, msg:message}; // Return an object with both values
  };


  const handleIsSelectMedia=(e,index)=>{
    let updatedItem=[...data?.items]
     updatedItem[index][e.target.name] = e.target.checked ? "*" : null
     setData({ ...data, items:[...updatedItem] });
 }

 const handleMedia = (name, uploaded, index) => {
  try {
    setData((prevData) => {
      const updatedItems = prevData?.items?.map((item, i) => 
        i === index 
          ? { ...item, [name]: uploaded?.name || '' } 
          : item
      ) || [];
     const previousAsset= prevData?.items?.[index]?.[name] || '';
     let updatedProp={ ...prevData, items: updatedItems }
      // trigger asset handling logic
      handleCeAssets(previousAsset, uploaded?.name, updatedProp);
      return { ...updatedProp };
    });
  } catch (error) {
    console.debug('Error updating media:', error);
  }
};




  return (
    <div className="card-prop-main-ui acccordion-item-list video-hotpot-panel">
      <div className="row pb-3">
        {ACLHelper.isFeatureEnabled(4, 2) && (
          <div className="mb-3 col-lg-6 col-md-12 col-sm-12">
            <div className="d-flex justify-content-between">
              <label htmlFor="video" className="label">Browse Video</label>
              {/* <CustomCheckbox
                label="Fast Forward & Rewind"
                checked={data?.forward_rewind ?? true}
                name="forward_rewind"
                onChange={handleForward}
              /> */}
            </div>
            <UploaderPanel
              accept="video"
              fileName={data?.src}
              name={`vh-${id}_video`}
              setUploadedData={handleVideo}
            />
          </div>
        )}
        <div className="mb-3 col-lg-6 col-md-12 col-sm-12 poster-check">
          <CustomCheckbox
            label="Select a poster for this video"
            checked={isPoster}
            onChange={(e) => handleFirstFrame(e.target.checked)}
            className={"select-ck"}
          />
          {isPoster && (
            <UploaderPanel
              accept="image"
              fileName={data?.poster}
              name={`vh-${id}_${Datahelper?.getTime()}-poster`}
              setUploadedData={handlePoster}
            />
          )}
        </div>
      </div>
      <DndProvider backend={HTML5Backend}>
        {[{id:-1,title:'Settings'},...data?.items]?.map((el, index) => {
          let point =null;
          let cIndex=(index-1);
          let validation=false;
          let name='';
          if(Array.isArray(el?.point)&& el?.id !== -1){
            point= el?.point?.map(Number)
            name=`${index}. [${point[0]+':'+point[1]}] `;
            validation=validatePoint(el?.point,cIndex)
          }
          return (
            <AccordionItem
              key={index}
              className="inner-summary"
              name={name}
              index={cIndex}
              el={el}
              data={data?.items}
              listUpdate={handleUpdateAccList}
              expanded={expanded}
              handleAccordionChange={handleChange}
              summary={ el.id !== -1 ? { delete: { min: 1 }, title: { name: 'title' } } : {}}
              dynamicDetailsComponent={
                el?.id !== -1 ?  <> 
                <div className="card-prop-description">
                  <div className="row">
                      <div className="col-lg-7  col-md-12">
                        { point && <p className="description-text label">Hotspot: {(Number(point[1]||0) - Number(point[0]||0)).toFixed(2)} seconds</p>}
                        <Slider
                          getAriaLabel={() => 'Minimum distance shift'}
                          value={point}
                          onChange={(e)=>handleHotpot(e,cIndex)}
                          valueLabelDisplay="on"
                          getAriaValueText={(value) => `${value} seconds`}
                          disableSwap
                          min={0.1}
                          className="black-slider mb-2"
                          max={props?.duration||55}
                        />
                          { validation?.overlaps && <p className="error-duration m-0 mt-4">{validation?.msg}</p>}
                        </div>
                        <div className="col-lg-5  col-md-12">
                        <p className="description-text label">Hotspot Poster </p>
                        {<UploaderPanel accept={'image'} fileName={el?.sch} name={`vh-sch-${id}_${Datahelper.getTime()}-${index + 1}`} setUploadedData={(e) => handleMedia('sch',e, cIndex)} />}
                          <CustomCheckbox
                            label={'Select Audio'}
                            className="ck-check-box"
                            checked={!(el?.audio===-1 || !el?.audio)||null}
                            name="audio" 
                            onChange={(e) => handleIsSelectMedia(e,cIndex)} 
                           />
                        {!(el?.audio===-1 || !el?.audio) && <UploaderPanel accept={'audio'} fileName={el?.audio} name={`vh-${id}_${Datahelper.getTime()}-${index + 1}`} setUploadedData={(e) => handleMedia('audio',e, cIndex)} />}
                        </div>
                  </div>
                  <p className="description-text label">Content</p>
                  <RichTextEditor
                    styles={{ minHeight: 80 }}
                    data={el?.desc || ""}
                    eventHandler={(value) => eventHandlers(value, cIndex)}
                  />
                </div></> :<div>
                  <p className="description-text label">Introduction Audio</p>
                    <UploaderPanel accept={'audio'} fileName={data?.in_audio} name={`vh-in_audio-${id}_${Datahelper.getTime()}-${index + 1}`} setUploadedData={(e) => handleIntroAudio('in_audio',e)} />
                  <p className="description-text label">Introduction</p>
                  <RichTextEditor
                    styles={{ minHeight: 180 }}
                    data={data?.intro}
                    eventHandler={(value) => textUpdate('intro',value)}
                  />
                  <p className="description-text label">Result Info Text</p>
                  <RichTextEditor
                    styles={{ minHeight: 180 }}
                    data={data?.e_title}
                    eventHandler={(value) => textUpdate('e_title',value)}
                  />
                  <p className="description-text label">Summary</p>
                  <RichTextEditor
                    styles={{ minHeight: 180 }}
                    data={data?.summary}
                    eventHandler={(value) => textUpdate('summary',value)}
                  />
                  <p className="description-text label">Unsuccessful Audio</p>
                  <UploaderPanel accept={'audio'} fileName={data?.wrng_audio} name={`vh-wrng_audio-${id}_${Datahelper.getTime()}-${index + 1}`} setUploadedData={(e) => handleIntroAudio('wrng_audio',e)} />
                  <p className="description-text label">Unsuccessful Attempt</p>
                  <RichTextEditor
                    styles={{ minHeight: 180 }}
                    data={data?.warning}
                    eventHandler={(value) => eventUpdate({name:'warning',value:value})}
                  />
                  <p className="description-text label">Description</p>
                  <RichTextEditor
                    styles={{ minHeight: 180 }}
                    data={data?.description}
                    eventHandler={(value) => eventUpdate({name:'description',value:value})}
                  />
                </div>
              }
            />
          )
        })}
      </DndProvider>
    </div>
  )
}

export default VideoHotspotPanel